import useStore from '@app/store/useStore';

export async function checkHttpStatusCode(response: Response) {
  if (response.status >= 200 && response.status <= 299) {
    return response.json();
  }
  if (response.status == 401) {
    useStore.getState().user.logout();
  }

  throw Error(response.statusText);
}
