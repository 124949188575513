import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { deleteCookie, getCookie } from 'cookies-next';
import fetch from 'isomorphic-unfetch';

import useStore from '@app/store/useStore';
import createCache from '@utilities/Apollo/CreateCache';
import { AppApolloClient } from '@utilities/Apollo/types';
import { Roles } from '@utilities/Security/roles';

let apolloClient: AppApolloClient | null = null;

function createLink(providedToken?: string): ApolloLink {
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = typeof window !== 'undefined' ? getCookie('nxtlvl-token') : providedToken || null;
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        'X-Api-Token': token ? `Bearer ${token}` : '',
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward, response }) => {
    if (networkError) {
      const statusCode: number = (networkError as any).statusCode as number;
      switch (statusCode) {
        case 401: {
          const isAdminAccess = useStore.getState().user.data.roles.includes(Roles.ADMIN_ACCESS);
          useStore.getState().user.logout();
          deleteCookie('nxtlvl-token');
          deleteCookie('refresh_token');
          window.location.href = isAdminAccess ? '/admin?sessionExpired=1' : '/account/login?sessionExpired=1';
          return;
        }
      }
    }
  });

  const options = {
    // Use fetch() polyfill on the server
    fetch,
    uri:
      typeof window !== 'undefined'
        ? '/graphql'
        : process.env.GRAPHQL_URL || `${process.env.NEXT_PUBLIC_ENTRYPOINT}/graphql`,
    credentials: 'same-origin',
    headers: {
      // cookie,
    },
  };

  const httpLink = new HttpLink(options);

  return authLink.concat(errorLink).concat(httpLink);
}

function create(initialState: any, token?: string): AppApolloClient {
  let defaultOptions;
  if (typeof window === 'undefined') {
    //We don't want any cache to be stored server side
    defaultOptions = {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    };
  } else {
    //We immediately show results, but check in the background if any changes occured, and eventually update the view
    defaultOptions = {
      query: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
    };
  }

  return new ApolloClient({
    ...defaultOptions,
    cache: createCache().restore(initialState || {}),
    connectToDevTools: typeof window !== 'undefined',
    link: createLink(token),
    credentials: 'same-origin',
    ssrMode: typeof window === 'undefined', // Disables forceFetch on the server (so queries are only run once)
  });
}

export default function initApollo(initialState?: any, token?: string): AppApolloClient {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return create(initialState, token);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState);
  }

  return apolloClient;
}
