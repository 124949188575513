import { ColorScheme, MantineThemeColors } from '@mantine/styles';
import { getCookie, setCookie } from 'cookies-next';
import jwt_decode from 'jwt-decode';
import create from 'zustand';
// eslint-disable-next-line import/no-internal-modules
// eslint-disable-next-line import/no-internal-modules
import { immer } from 'zustand/middleware/immer';

import { Roles } from '@app/utilities/Security/roles';
import { fetcher } from '@utilities/fetcher';

export interface IUserSettings {
  colorScheme: ColorScheme;
  themeColor: keyof MantineThemeColors;
  useLevelNameAsSalutation?: boolean;
}

export interface IUser {
  exp: number;
  iat: number;
  roles: string[];
  username: string;
  isVerified: boolean;
  hasReadingHabits?: boolean;
  userId?: string;
  studentId?: string;
  schoolClassId?: string;
  schoolId?: string;
  schoolYearId?: string;
  givenName?: string;
  familyName?: string;
  settings: IUserSettings;
}

export interface MyState {
  user: {
    data?: IUser;
    loginWithUserData: (data: IUser) => void;
    login: (token: string) => void;
    logout: () => void;
  };
  settings: IUserSettings;
  actions: {
    toggleColorScheme: (scheme?: ColorScheme) => void;
    changeThemeColor: (color: keyof MantineThemeColors) => void;
    toggleLevelNameAsSalutation: (value?: boolean) => void;
  };
}

const token = getCookie('nxtlvl-token');
const defaultUserData: IUser = token ? (jwt_decode(token as string) as IUser) : undefined;

const useStore = create<MyState>()(
  immer((set) => ({
    user: {
      loginWithUserData: (data) => {
        return set((state) => {
          state.settings = data.settings;
          delete data.settings;
          state.user.data = data;
        });
      },
      login: (token) => {
        return set((state) => {
          state.user.data = jwt_decode(token);
        });
      },
      logout: () => {
        return set((state) => {
          if (state.user) {
            state.user.data = undefined;
          }
        });
      },
      data: defaultUserData,
    },
    settings: {
      colorScheme: 'light',
      themeColor: 'red',
      useLevelNameAsSalutation: undefined,
      ...defaultUserData?.settings,
    },
    actions: {
      toggleColorScheme: (scheme) =>
        set((state) => {
          state.settings.colorScheme = scheme || (state.settings.colorScheme === 'dark' ? 'light' : 'dark');
        }),
      changeThemeColor: (color) =>
        set((state) => {
          state.settings.themeColor = color;
        }),
      toggleLevelNameAsSalutation: (value?: boolean) =>
        set((state) => {
          state.settings.useLevelNameAsSalutation =
            value !== undefined ? value : !state.settings.useLevelNameAsSalutation;
        }),
    },
  }))
);

useStore.subscribe(async (state, prevState) => {
  if (!state.user.data.roles.includes(Roles.ADMIN_ACCESS) && state.settings !== prevState.settings) {
    const userId = state.user.data?.userId;
    if (userId) {
      await fetcher(`/users/${userId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
        body: JSON.stringify({
          settings: state.settings,
        }),
      });
      const { token } = await fetcher('/account/token/refresh', {
        method: 'POST',
      });
      if (token) {
        state.user.login(token);
        setCookie('nxtlvl-token', token);
      }
    }
  }
});

export default useStore;
