import { InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

import fragmentTypes from '@utilities/Apollo/FragmentTypes.generated';
import { StrictTypedTypePolicies } from '@utilities/Apollo/Helpers.generated';
import { relayPagination } from '@utilities/Apollo/utilities';

const typePolicies: StrictTypedTypePolicies = {
  Query: {
    fields: {
      schoolClasses: relayStylePagination(),
      readAchievements: relayStylePagination(),
      // comparisons: relayPagination('Comparison'),
      levels: relayStylePagination(),
      // libraries: relayStylePagination('Library'),
      schools: relayStylePagination(),
      students: relayStylePagination(),
      // userGroups: relayPagination('UserGroup'),
    },
  },
  Student: {
    fields: {
      readAchievements: relayStylePagination(),
    },
  },
  // ReadAchievement: {
  //   fields: {
  //     createTime: {
  //       merge(_existing, incoming) {
  //         return parseISO(incoming);
  //       },
  //     },
  //   },
  // },
};

export default function createCache() {
  return new InMemoryCache({
    possibleTypes: fragmentTypes.possibleTypes,
    typePolicies,
  });
}
