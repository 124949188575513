/* eslint-disable */

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      'Book',
      'BookCategory',
      'BookRating',
      'Comparison',
      'DynamicPdf',
      'DynamicPdfInstance',
      'Level',
      'LevelAchievement',
      'Library',
      'MediaObject',
      'ReadAchievement',
      'ReadingHabit',
      'School',
      'SchoolClass',
      'SchoolYear',
      'Signup',
      'Student',
      'User',
    ],
  },
};
export default result;
