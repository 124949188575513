import axios, { AxiosRequestConfig } from 'axios';
import { getCookie } from 'cookies-next';
import merge from 'lodash/merge';

import { checkHttpStatusCode } from '@utilities/checkHttpStatusCode';

export async function fetcher(url: string, options?: RequestInit) {
  const token = typeof window !== 'undefined' ? getCookie('nxtlvl-token') : null;
  return await fetch(
    url,
    merge(
      {
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Token': token ? `Bearer ${token}` : '',
        },
      },
      options
    )
  ).then(checkHttpStatusCode);
}

export async function axiosFetcher(url: string, options?: AxiosRequestConfig) {
  const token = typeof window !== 'undefined' ? getCookie('nxtlvl-token') : null;

  return await axios(
    merge(
      {
        url,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Token': token ? `Bearer ${token}` : '',
        },
      },
      options
    )
  );
}
