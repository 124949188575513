import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';

const DefaultWrapper = dynamic(async () => {
  const mod = await import('@components/Default/Layout/DefaultWrapper');
  return mod.DefaultWrapper;
});

const AppWrapper = dynamic(async () => {
  const mod = await import('@components/App/Layout/AppWrapper');
  return mod.AppWrapper;
});

const AdminWrapper = dynamic(async () => {
  const mod = await import('@components/Admin/Layout/AdminWrapper');
  return mod.AdminWrapper;
});

export const LayoutWrapper: FunctionComponent = (props) => {
  const router = useRouter();
  const isApp = router.pathname.startsWith('/app');
  const isAdmin = router.pathname.startsWith('/admin');

  if (isApp) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <AppWrapper {...props} />;
  }

  if (isAdmin) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <AdminWrapper {...props} />;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <DefaultWrapper {...props} />;
};
